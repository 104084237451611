
'use strict';
import $ from 'jquery';
import Parsley from 'parsleyjs';
import Checkbox from './Checkbox';

export default class Form {

  constructor() {

    let $form = $('form');

    let ajaxurl = 'https://heureka.network/lib/signup';
    $form.find('.submit').on('click', (e) => {
      e.preventDefault();
      $form.submit();
    });
    $form.on('submit', (e) => {
      e.preventDefault();

      if ($form.parsley().isValid()) {
        $form.find('.submit').attr('disabled', true);
        $.ajax({
          url: ajaxurl,
          method: 'POST',
          data: $form.serialize(),
          success: (result) => {
            let r = $.parseJSON(result);
            if (r.success) {
              gtag('event', 'subscribe', {'event_action' : 'subscribed', 'event_label' : (r.teammember) ? 'team' : 'user', 'value' : 2});
            }
            this._message(r.message);
          }
        });
      }


    });


    $form.parsley().on('form:error', function() {
      if ($('#team-member').is(':checked')) {
        Checkbox.updateHeight();
      }
    });
  }

  _message(msg) {
    let $form = $('form');
    $form[0].reset();
    $form.find('.submit').attr('disabled', false);
    $('.msg-container').html(msg);
  }

}

