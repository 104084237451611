// Main javascript entry point
// Should handle bootstrapping/starting application

$(window).on("load", function() {
  let particles = require('../_data/particles.json');
  particlesJS('particles-js', particles);
});

/* First CSS File */
const giftofspeed = document.createElement('link');
giftofspeed.rel = 'stylesheet';
giftofspeed.href = 'styles/main.css';
giftofspeed.type = 'text/css';
const godefer = document.getElementsByTagName('link')[0];
godefer.parentNode.insertBefore(giftofspeed, godefer);

'use strict';
import $ from 'jquery';
import particles from 'particles.js';
import Font from './Webfont';
import Form from './Form';
import Checkbox from './Checkbox';


$(() => {

  new Font();
  new Form();
  new Checkbox();

  setTimeout(() => {
    $('.scroll-down').addClass('show');
  }, 2000);

  $('.scroll-down').on('click', (e) => {
    e.preventDefault();
    $('html, body').animate({scrollTop: $(window).height()}, 1200);
  });

  $(window).on('scroll', () => {
    $('.main-header')[($(window).scrollTop() > $(window).height() / 2) ? 'addClass' : 'removeClass']('is-visible');
  });

  $('.sharing-container a').on('click', (e) => {
    let $this = $(e.currentTarget);
    let cat = $this.data('title');
    gtag('event', 'share', {'event_action' : 'share', 'event_label' : cat, 'value' : 1});
  });

});

