
'use strict';
import $ from 'jquery';

export default class Checkbox {

  constructor() {

    $('#team-member').on('change', () => {
      if ($('#team-member').is(':checked')) {
        $('.hidden-container').css({'max-height' : $('.hidden-content').height()});
      } else {
        $('.hidden-container').css({'max-height' : 0});
      }
    });

  }

  static updateHeight() {
    $('.hidden-container').css({'max-height' : $('.hidden-content').height()});
  }
}