
'use strict';
import $ from 'jquery';
import WebFont from 'webfontloader';

export default class Font {

  constructor() {
    WebFont.load({
      google: {
        families: ['Lato:300,400', 'Montserrat:700']
      }
    });
  }
}